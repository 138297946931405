import axios from 'axios';
import Cookies from 'js-cookie';

let erp_utils_api;

export const init_erp_utils_api_request = () => {
    // basic axios instance
    erp_utils_api = axios.create({
        baseURL: process.env.REACT_APP_ERP_UTILS_API
    });

    // set interceptors for request
    erp_utils_api.interceptors.request.use((config) => {
        config.headers["Authorization"] = `Bearer ${Cookies.get("token")}`;
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    // set interceptors for response
    erp_utils_api.interceptors.response.use((request) => {
        return request;
    }, (error) => {
        if (error.response.status.toString() === '400' || error.response.status.toString() === '401') {
            if (sessionStorage.getItem('reloadUrl') !== undefined) {
                let reloadUrl = process.env.REACT_APP_LOGIN_URL + sessionStorage.getItem('reloadUrl') + window.location.href;
                window.location.replace(reloadUrl);
            }
        }
        return Promise.reject(error);
    });
};

// ----------------------------------------------
export const getAvailableCronjobs = () => {
    return erp_utils_api.get('/cronjob/available').then((res) => {
        return res.data;
    });
};

export const getAllCronjobsStatus = () => {
    return erp_utils_api.get('/cronjob').then((res) => {
        return res.data;
    });
};

export const getCronjobStatusByJobName = (jobName) => {
    return erp_utils_api.get(`/cronjob?job=${jobName}`).then((res) => {
        return res.data;
    });
};

export const createCronjobByJobNameAndCronString = (jobName, cronString) => {
    return erp_utils_api.post('/cronjob', {
        "job": jobName,
        "timeSpec": cronString,
    }).then((res) => {
        return res;
    });
};

export const deleteCronjobByJobName = (jobName) => {
    return erp_utils_api.delete(`/cronjob?job=${jobName}`).then((res) => {
        return res;
    });
};
