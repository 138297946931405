import React from 'react';
import { Form, Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';

const CronTabForm = (props) => {
  const [form] = Form.useForm()
  const [time, setTime] = useState('1');
  const minuteArray = [...Array(45).keys()].map(val => ({ label: val + 15, value: val + 15 }));

  useEffect(() => {
    let string = props.cronString.split(" ");
    let newTime = string[1].split("/")['1'];

    form.setFields([{ name: 'minute', value: newTime }]);
    setTime(newTime);
  }, [props.cronString, form]);

  const changeTime = (value) => {
    form.setFields([{ name: 'minute', value: value }]);
    props.setCronString(`0 0/${value} * * * *`);
    setTime(value);
  }
  return (
    <Form
      form={form}
      colon={false}
      layout='horizontal'
      onValuesChange={(event, values) => {
        props.setCronString(`0 0/${values['minute']} * * * *`)
      }}
      initialValues={{ minute: time }}
    >
      <Form.Item
        name="minute"
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
              <div> 間隔分鐘 </div>
              <Tooltip placement="right" title="除了間隔分鐘外，整點時也會同步一次">
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
            <div>：</div>
          </div>
        }
      >
        <Select
          value={time}
          size="middle"
          options={minuteArray}
          onChange={changeTime}
          style={{ width: '230px' }}
        />
      </Form.Item>
    </Form>
  );
};

export default CronTabForm;

