import React, { useState, useEffect } from 'react';
import { Tabs, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import WorkOrderJob from './work-order-job.js';
import OtherJobs from './other-jobs.js';
import { getAvailableCronjobs } from '../../api/erp-utils-api.js';

const JobList = () => {
    const [initOK, setInitOK] = useState(false);
    const [availableJobs, setAvailableJobs] = useState(null);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        getAvailableCronjobs().then((data) => {
            setAvailableJobs(data);
            setHasError(false);
        }).catch((err) => {
            message.error("獲取資料出現錯誤，請稍後再試。");
            setHasError(true);
        }).then(() => {
            setInitOK(true);
        });
    }, []);

    return (
        !initOK
            ?
            <LoadingOutlined />
            :
            !hasError
                ?
                availableJobs === null
                    ?
                    <WorkOrderJob />
                    :
                    <Tabs
                        tabBarStyle={{ marginBottom: '24px' }}
                        items={[
                            { key: 'work-order-job', label: '工單同步', children: <WorkOrderJob /> },
                            { key: 'other-jobs', label: '其他功能', children: <OtherJobs availableJobs={availableJobs} /> },
                        ]}
                    />
                :
                <div> 獲取資料出現錯誤，請稍後再試。 </div>
    );
};

export default JobList;